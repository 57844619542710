import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { costFormat, formatDate, getDaysDifference, truncateNotes } from 'src/utils/CommonUtils';
import DatePickerInputPendingInvoive from 'src/components/DatePickerInputPendingInvoive';
import InvoiceDownloadTemplate from 'src/containers/client-invoice-container/InvoiceDownloadTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};

const InvoiceTable = ({ status, data, tableTitle, initialColumns, onClick, actionClick }) => {
    const contentRef2 = useRef();
    const [matchedItem, setMatchedItem] = useState(null);

    // const handleDownload = async (item) => {
    //     setMatchedItem(item); // Update the matched item in state

    //     // Wait for the component to render
    //     setTimeout(async () => {
    //         const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (landscape mode)
    //         const content = contentRef2.current;

    //         // Use jsPDF's html method to render the HTML, which will include inline styles
    //         pdf.html(content, {
    //             callback: function (doc) {
    //                 // After rendering the HTML, we save the PDF with a name
    //                 doc.save(`Invoice-${item.clientInvoiceNo}.pdf`);
    //             },
    //             x: 4, // X position on the page
    //             y: 4, // Y position on the page
    //             width: 290, // Width of the content
    //             windowWidth: 1080, // Width of the window to allow better scaling
    //         });
    //     }, 0);
    // };


    const handleDownload = async (item) => {
        setMatchedItem(item); // Update the matched item in state

        // Wait for the component to render and ensure all images are loaded
        setTimeout(async () => {
            const content = contentRef2.current;

            // Ensure all images in content are loaded
            const images = content.querySelectorAll('img');
            let loadedImages = 0;
            const totalImages = images.length;

            // Convert image to base64
            const convertImageToBase64 = (img) => {
                return new Promise((resolve, reject) => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const image = new Image();
                    image.crossOrigin = 'anonymous'; // For CORS handling

                    image.onload = () => {
                        canvas.width = image.width;
                        canvas.height = image.height;
                        ctx.drawImage(image, 0, 0);
                        const base64 = canvas.toDataURL('image/png');
                        resolve(base64);
                    };

                    image.onerror = reject;
                    image.src = img.src;
                });
            };

            // Preload all images and convert to base64
            const imagePromises = Array.from(images).map((img) => convertImageToBase64(img));
            const base64Images = await Promise.all(imagePromises);

            // Replace image src with base64 data
            Array.from(images).forEach((img, index) => {
                img.src = base64Images[index];
            });

            // Generate the PDF after images are fully loaded
            generatePDF(content, item);
        }, 0);
    };

    // Function to generate PDF once images are loaded
    const generatePDF = (content, item) => {
        const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (landscape mode)

        // Use jsPDF's html method to render the HTML, which will include inline styles and base64 images
        pdf.html(content, {
            callback: function (doc) {
                // After rendering the HTML, save the PDF with a name
                doc.save(`Invoice-${item.clientInvoiceNo}.pdf`);
            },
            x: 4, // X position on the page
            y: 4, // Y position on the page
            width: 290, // Width of the content
            windowWidth: 1080, // Width of the window to allow better scaling
            useCORS: true, // Enable CORS to handle external images
        });
    };


    // const handleDownload = async (item) => {
    //     setMatchedItem(item); // Update the matched item in state

    //     // Wait for the component to render
    //     setTimeout(async () => {
    //         await html2canvas(contentRef2.current, {
    //             scale: 5, // Increase the scale for better quality
    //             useCORS: true, // Enable CORS to handle external images (if any)
    //             logging: true, // Log the process to console for debugging
    //             backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
    //         }).then((canvas) => {
    //             // Convert canvas to image data
    //             const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //             const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (portrait mode)

    //             // Adjust the image size to fit the PDF page
    //             const pdfWidth = pdf.internal.pageSize.getWidth();
    //             const pdfHeight = pdf.internal.pageSize.getHeight();
    //             const canvasWidth = canvas.width;
    //             const canvasHeight = canvas.height;

    //             const scaleX = pdfWidth / canvasWidth;
    //             const scaleY = pdfHeight / canvasHeight;
    //             const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

    //             const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
    //             const yOffset = 0; // Set yOffset to 0 to start from the top

    //             // Add image to PDF with calculated position and scaling
    //             pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

    //             // Save the PDF with a name
    //             pdf.save(`Invoice-${item.clientInvoiceNo}.pdf`);
    //         });
    //     }, 0);
    // };



    // const { isSticky, elementRef } = useSticky();
    const [tableData, setTableData] = useState(data);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [draggedColIndex, setDraggedColIndex] = useState(null);
    const [columns, setColumns] = useState(initialColumns);
    const [selectedDueNonDue, setSelectedDueNonDue] = useState("all");

    // Initialize tooltips when data or columns change
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        return () => {
            tooltipList.forEach(tooltip => tooltip.dispose());
        };
    }, [data, columns]); // Reinitialize tooltips when either data or columns change

    useEffect(() => {
        setTableData(data)
    }, [data]); // Reinitialize tooltips when either data or columns change

    // Row Drag Handlers
    const handleDragStartRow = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOverRow = (index, event) => {
        event.preventDefault();
    };

    const handleDropRow = (index) => {
        const updatedData = [...tableData];
        const [draggedItem] = updatedData.splice(draggedIndex, 1);
        updatedData.splice(index, 0, draggedItem);
        setTableData(updatedData);
        setDraggedIndex(null);
    };

    // Column Drag Handlers
    const handleDragStartCol = (index) => {
        setDraggedColIndex(index);
    };

    const handleDragOverCol = (index, event) => {
        event.preventDefault();
    };

    const handleDropCol = (index) => {
        const updatedColumns = [...columns];
        const [draggedColumn] = updatedColumns.splice(draggedColIndex, 1);
        updatedColumns.splice(index, 0, draggedColumn);
        setColumns(updatedColumns);
        setDraggedColIndex(null);
    };

    // Handle Column Visibility
    const handleColumnVisibilityChange = (id) => {
        setColumns((prevColumns) =>
            prevColumns.map((col) =>
                col.id === id ? { ...col, visible: !col.visible } : col
            )
        );
    };

    // Handle View All Checkbox
    const handleViewAllChange = (event) => {
        const isChecked = event.target.checked;
        setColumns((prevColumns) =>
            prevColumns.map((col) => ({ ...col, visible: isChecked }))
        );
    };

    // Handle Apply Changes
    const handleApply = () => {
        // const invoiceTableColumn = {};
        let invoiceTableColumn = JSON.parse(localStorage.getItem('invoiceTableColumn')) || {};
        invoiceTableColumn[status] = columns;

        // Convert the object to a JSON string and store it in localStorage
        localStorage.setItem('invoiceTableColumn', JSON.stringify(invoiceTableColumn));
    };

    const [selectedRows, setSelectedRows] = useState([]);

    // Handler to toggle select all rows
    const handleSelectAll = () => {
        if (selectedRows.length === tableData.length) {
            // Deselect all rows
            setSelectedRows([]);
        } else {
            // Select all rows (using their unique IDs or indices)
            setSelectedRows(tableData.map((item) => item.sNo)); // Assuming 'sNo' is the unique identifier for rows
        }
    };

    // Handler to toggle selection for individual rows
    const handleSelectRow = (sNo) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(sNo)) {
                // Deselect the row
                return prevSelectedRows.filter((rowId) => rowId !== sNo);
            } else {
                // Select the row
                return [...prevSelectedRows, sNo];
            }
        });
    };


    // Table Scroll Code Start
    const elementRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const debounceTimeoutRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            clearTimeout(debounceTimeoutRef.current);

            debounceTimeoutRef.current = setTimeout(() => {
                if (elementRef.current) {
                    const { top, bottom } = elementRef.current.getBoundingClientRect();
                    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

                    // Check if the table is within the viewport and above the top threshold
                    const shouldScroll = top < 150 && bottom > 0;

                    // Only update the state if it needs to change
                    if (shouldScroll !== isScrollable) {
                        setIsScrollable(shouldScroll);
                    }
                }
            }, 150); // Debounce delay
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(debounceTimeoutRef.current);
        };
    }, [isScrollable]);

    const handleChange = (event) => {
        setSelectedDueNonDue(event.target.value);
        console.log("event.target.value", event.target.value, data);
    }
    useEffect(() => {
        const currentDate = new Date();
        const filteredData = data.filter((invoice) => {
            const dueDate = new Date(invoice.dueDate);
            if (selectedDueNonDue === "due") {
                return dueDate < currentDate; // Past due invoices
            } else if (selectedDueNonDue === "non-due") {
                return dueDate >= currentDate; // Not yet due invoices
            }
            return true; // "all" case returns everything
        });

        setTableData(filteredData);
    }, [selectedDueNonDue]);

    return (
        <div>
            <div className='mb-3 d-flex align-items-center gap-3 justify-content-between'>
                <div className="d-flex align-items-center gap-3 bg-white postionTop-0">
                    <div style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}>
                        {matchedItem && <InvoiceDownloadTemplate ref={contentRef2} matchedItem={matchedItem} />}
                    </div>

                    {status !== "Cancelled" && (
                        <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9" onClick={onClick}>
                            <i className="mdi mdi-file-document-outline"></i> Summary</button>
                    )}
                    <div className="dropdown">
                        <button className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2" type="button" data-bs-toggle="dropdown" aria-expanded="true"><img src="/assets/images/add-column.svg" className="img-fluid" alt="add-column" /> Manage Columns</button>

                        <div className="dropdown-menu border wsetColumn">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="viewAll"
                                    onChange={handleViewAllChange}
                                />
                                <label className="form-check-label" htmlFor="viewAll">
                                    View All
                                </label>
                            </div>
                            <div className="mx-hight200px">
                                {columns.map((col) => (
                                    <div key={col.id} className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={col.id}
                                            checked={col.visible}
                                            onChange={() => handleColumnVisibilityChange(col.id)}
                                        />
                                        <label className="form-check-label" htmlFor={col.id}>
                                            {col.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
                {status === "Pending" && (
                    // <div className="d-flex align-items-center flex-wrap gap-28">
                    //     <div className="form-check checked-primary d-flex align-items-center gap-2">
                    //         <input className="form-check-input" type="radio" name="horizontal" id="horizontal1" />
                    //         <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal1" >{" "} All {" "}</label>
                    //     </div>
                    //     <div className="form-check checked-primary d-flex align-items-center gap-2">
                    //         <input className="form-check-input" type="radio" name="horizontal" id="horizontal4" />
                    //         <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal4" >{" "}Due{" "}</label>
                    //     </div>
                    //     <div className="form-check checked-primary d-flex align-items-center gap-2">
                    //         <input className="form-check-input" type="radio" name="horizontal" id="horizontal3" />
                    //         <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal3"> {" "} Non-due{" "}</label>
                    //     </div>
                    // </div>
                    <div className="d-flex align-items-center flex-wrap gap-28">
                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="horizontal"
                                id="horizontal1"
                                value="all"
                                checked={selectedDueNonDue === "all"}
                                onChange={handleChange}
                            />
                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal1">
                                {" "} All {" "}
                            </label>
                        </div>

                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="horizontal"
                                id="horizontal4"
                                value="due"
                                checked={selectedDueNonDue === "due"}
                                onChange={handleChange}
                            />
                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal4">
                                {" "} Due {" "}
                            </label>
                        </div>

                        <div className="form-check checked-primary d-flex align-items-center gap-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="horizontal"
                                id="horizontal3"
                                value="non-due"
                                checked={selectedDueNonDue === "non-due"}
                                onChange={handleChange}
                            />
                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal3">
                                {" "} Non-due {" "}
                            </label>
                        </div>
                    </div>

                )}
            </div>
            {/* Table Rendering */}
            <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                {/* <div className={`table-body-scrollable`}> */}
                {tableTitle && <h3 className="card-title">{tableTitle}</h3>}
                {tableData.length === 0 ? (
                    <p>No invoices available.</p>
                ) : (
                    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                        <thead>
                            <tr>
                                {columns.map((col, index) => (
                                    col.visible && (
                                        <th
                                            key={col.id}
                                            draggable
                                            onDragStart={() => handleDragStartCol(index)}
                                            onDragOver={(e) => handleDragOverCol(index, e)}
                                            onDrop={() => handleDropCol(index)}
                                        >
                                            {col.label}
                                        </th>
                                    )
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    draggable
                                    onDragStart={() => handleDragStartRow(rowIndex)}
                                    onDragOver={(e) => handleDragOverRow(rowIndex, e)}
                                    onDrop={() => handleDropRow(rowIndex)}
                                >

                                    {columns.map((col) => (
                                        col.visible && (
                                            <td key={col.id}>
                                                {col.id === 'srNo' && (rowIndex + 1)}
                                                {col.id === 'clientInvoiceNo' && row.clientInvoiceNo}
                                                {col.id === 'clientName' && (
                                                    <div className="user-cell"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-custom-class="tooltip-primary"
                                                        data-bs-title={`${row.clientName}`}
                                                    >
                                                        <span className="user-initials">{getInitials(row.clientName)}</span>
                                                        {/* {row.clientName} */}
                                                        <div className=''>
                                                            {truncateNotes(row.clientName)}
                                                            <div className='ManagerName font-12'>{row?.invoiceTitle}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {col.id === 'taxableAmount' && (row.currency === "₹" ? costFormat(row.subTotal.$numberDecimal) : 0)}
                                                {col.id === 'gstAmount' && (row.currency === "₹" ? costFormat(row.totalCostInr.$numberDecimal - (row.subTotal.$numberDecimal)) : 0)}
                                                {col.id === 'totalCostInr' && costFormat(row.totalCostInr.$numberDecimal)}
                                                {col.id === 'invoiceDate' && (row.invoiceDate ? formatDate(row.invoiceDate) : '')}
                                                {col.id === 'paymentStatus' && //row.paymentStatus
                                                    <DatePickerInputPendingInvoive
                                                        placeholder="MM/DD/YYYY"
                                                        value=""
                                                        onChange={(val) => {
                                                            actionClick({ status: "Paid", paidDate: val }, row._id)
                                                        }} />
                                                }
                                                {col.id === 'paidDate' && (row.paidDate ? formatDate(row.paidDate) : '')}
                                                {col.id === 'cancelledDate' && (row.cancelledDate ? formatDate(row.cancelledDate) : '')}
                                                {col.id === 'raisedBy' && (
                                                    <div className="user-cell">
                                                        <span className="user-initials">{getInitials(row.raisedBy)}</span> {row.raisedBy}
                                                    </div>
                                                )}
                                                {col.id === 'dueDays' && getDaysDifference(row.invoiceDate)}
                                                {col.id === 'rollbacked' && (
                                                    <div
                                                        className="detaset mxwsetnotes"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-custom-class="tooltip-primary"
                                                        data-bs-title={`${row.rollbackReason}`}
                                                    >
                                                        {truncateNotes(row.rollbackReason)}
                                                    </div>
                                                )}
                                                {col.id === 'action' && (
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-primary-600 not-active px-18 py-8 dropdown-toggle toggle-icon text-sm"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            Action
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link className="dropdown-item" to={`/invoice/edit/${row._id}`}>
                                                                    <i className="mdi mdi-pencil-outline"></i> Edit </Link></li>
                                                            <li><a className="dropdown-item" href="javascript:void(0)" onClick={() => handleDownload(row)}><i className="mdi mdi-file-pdf-outline"></i> PDF</a></li>
                                                            <li>
                                                                <Link className="dropdown-item" to={`/invoice/edit/${row._id}/d`}>
                                                                    <i className="mdi mdi-content-copy"></i> Duplicate </Link>
                                                            </li>
                                                            <li>
                                                                {status === "Cancelled" ? (
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="javascript:void(0)"
                                                                        data-bs-toggle="modal" data-bs-target="#RollbackInvoice"
                                                                        onClick={() => actionClick({ status: row.cancelledStatus, rollback: true }, row._id)}
                                                                    >
                                                                        <i className="mdi mdi-reload"></i> Rollback
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="javascript:void(0)"
                                                                        data-bs-toggle="modal" data-bs-target="#RollbackInvoice"
                                                                        onClick={() => actionClick({ status: "Cancelled", cancelledStatus: row.status, cancelledDate: new Date().toISOString().split('T')[0] }, row._id)}
                                                                    >
                                                                        <i className="mdi mdi-close-circle-outline"></i> Cancel
                                                                    </a>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {col.id === 'notes' && (
                                                    <div
                                                        className="detaset mxwsetnotes"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-custom-class="tooltip-primary"
                                                        // data-bs-title={`${row.notes}`}
                                                        data-bs-title={row.notes && row.notes.trim() !== "" ? row.notes : "No notes available"}
                                                    >
                                                        {truncateNotes(row.notes)}
                                                    </div>
                                                )}
                                            </td>
                                        )
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default InvoiceTable