import { type } from "jquery";
import { useCallback, useEffect, useState } from "react";
import { useSelector,shallowEqual,useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIndividualProjectWiseCostAPI } from "src/api/PerfomanceAPI";
import MonthPicker from "src/components/MonthPicker";
import VehicleFuelChart from "src/components/VehicleFuelChart";
import { perfomanceSetProjectWiseWhereAction } from "./actions/PerfomanceActions";

const ProjectWiseCost=({setProjectId,user})=>{
    
const {userId} = useParams();
const dispatch = useDispatch();
// Dynamic data state
const {selectedMonth,selectedYear} = useSelector(state=>state.ProductionReducer,shallowEqual);
const [where,setWhere] = useState({
    userId,
    type:"gross",
    status:"total",
    month: selectedMonth,
    year: selectedYear,
    $expr: {
    $and: [
      { $eq: [{ $year: "$date" },selectedYear] },
      { $eq: [{ $month: "$date" }, selectedMonth] } 
    ]
  }});
const [projects, setProjects] = useState([]);
useEffect(() => {
    search(where);
    dispatch(perfomanceSetProjectWiseWhereAction({where}));
  }, [         
    where
  ]);

  const search = useCallback(async()=>{
     const projectCost  = await getIndividualProjectWiseCostAPI({where});
     setProjects(projectCost.data.data)
  },[where])

// const handleChangeDate = (date)=>{
//     const year = date.getFullYear();  // Example year
//     const month = date.getMonth()+1;  // Since month is 0-indexed in JavaScript, add 1
//     setWhere({
//       ...where,
//       month: month,
//       year: year,
//       $expr: {
//         $and: [
//           { $eq: [{ $year: "$date" },year] },
//           { $eq: [{ $month: "$date" }, month] } 
//         ]
//       }
//     }) 
//   }
  useEffect(()=>{
    setWhere({
        ...where,
        $expr: {
          $and: [
            { $eq: [{ $year: "$date" },selectedYear] },
            { $eq: [{ $month: "$date" }, selectedMonth] } 
          ]
        }
      })
  },[
    selectedMonth
    ,selectedYear
])

 return(
    <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24 px-0">
                        <div className="ms-auto d-flex justify-content-between border-bottom pb-24 align-items-center inwth">
                            <h4 className="card-title">Project Summary</h4>   
                            {/* <MonthPicker handleChangeDate={handleChangeDate} /> */}
                        </div>
                        <div className="card-body px-0 pt-18">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul
                                    className="nav focus-tab nav-pills mb-0"
                                    id="pills-tab-two"
                                    role="tablist"
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-6 active"
                                            id="pills-focus-GrossWorkingDays-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-focus-GrossWorkingDays"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-focus-GrossWorkingDays"
                                            aria-selected="true"
                                            onClick={()=>setWhere({...where,type:"gross"})}
                                        >
                                            Gross Working Days
                                        </button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-6"
                                            id="pills-focus-TotalWorkingDays-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-focus-TotalWorkingDays"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-focus-TotalWorkingDays"
                                            aria-selected="false"
                                            tabIndex={-1}
                                            onClick={()=>setWhere({...where,type:"net"})}
                                        >
                                            Total Working Days
                                        </button>
                                    </li> */}
                                </ul>
                                <div className="d-flex align-items-center flex-wrap gap-28">
                                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal1"
                                            onClick={()=>setWhere({...where,status:"approved"})}
                                            checked={where.status==="approved"}
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal1"
                                        >
                                            {" "}
                                            Approved {" "}
                                        </label>
                                    </div>
                                    <div className="form-check checked-warning d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal4"
                                            onClick={()=>setWhere({...where,status:"pending"})}
                                            checked={where.status==="pending"}
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal4"
                                        >
                                            {" "}
                                            Pending{" "}
                                        </label>
                                    </div>
                                    <div className="form-check checked-success d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal3"
                                            onClick={()=>setWhere({...where,status:"total"})}
                                            checked={where.status==="total"}
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal3"
                                        >
                                            {" "}
                                            Total{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-content" id="pills-tab-twoContent">
                                <div
                                    className="tab-pane fade active show"
                                    id="pills-focus-GrossWorkingDays"
                                    role="tabpanel"
                                    aria-labelledby="pills-focus-GrossWorkingDays-tab"
                                    tabIndex={0}
                                >   
                                 <VehicleFuelChart projects={projects} user={user} totalProjectsLabel="Total Projects" setProjectId={setProjectId} showModalButton={true}  /> {/* Pass chart data */}
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="pills-focus-TotalWorkingDays"
                                    role="tabpanel"
                                    aria-labelledby="pills-focus-TotalWorkingDays-tab"
                                    tabIndex={0}
                                >
                                     <VehicleFuelChart projects={projects} totalProjectsLabel="Total Projects" setProjectId={setProjectId} showModalButton={false} /> {/* Pass chart data */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 )
}
export default ProjectWiseCost