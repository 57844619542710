import React, { useMemo, useState, useEffect, useRef } from 'react';
import { costFormat, calculateDeviation, calculatePercentage } from 'src/utils/CommonUtils';
import SummariesComponent from './SummariesComponent';
import { useSticky } from 'src/hooks/useSticky';

// Function to get initials from manager name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts.map(part => part[0]).join('');
  return initials;
};

// Function to determine background color based on manager's name
const getBackgroundColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
  return color;
};

// Function to determine text color based on background color (light or dark)
const getTextColor = (bgColor) => {
  const rgb = bgColor.match(/\d+/g).map(Number);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
};

// Function to determine text color based on delivery percentage
const getClassName = (value) => {
  return value.includes('-') ? 'text-red' : 'text-green';
};


const PddstatsProject = (search) => {
  const [data, setData] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [activeButton, setActiveButton] = useState(null); // Track which button is active

  // Effect to handle data loading
  useEffect(() => {
    setData(search?.search || []);
    setOpenAccordion(null)
    setActiveButton(null)
  }, [search]);

  // Function to determine text color based on the deviation percentage
  const getClassName = (value) => {
    return value.includes('-') ? 'text-red' : 'text-green';
  };

  // Calculate totals
  let totalOAD = 0;
  let totalCMD = 0;

  search?.search.forEach((item) => {
    totalOAD += item.totalOad || 0;
    totalCMD += item.totalcmd || 0;
  });

  const trimmedQuery = (typeof search.searchQuery === 'string' ? search.searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? data.filter((item) =>
      item.ClientProjectCode.toLowerCase().includes(trimmedQuery) ||
      item.ClientRegisteredName.toLowerCase().includes(trimmedQuery) ||
      item.fullName.toLowerCase().includes(trimmedQuery) ||
      item.vertical.toLowerCase().includes(trimmedQuery)
    )
    : data; // Return all data if the searchQuery is empty

  const [totalOAD2, totalCMD2, totalDelivery2] = filteredData.reduce(
    ([oadSum, cmdSum, deliverySum], item) => [
      oadSum + (item.totalOad || 0),
      cmdSum + (item.totalcmd || 0),
      deliverySum + (item.totalDeliveryCost || 0)
    ],
    [0, 0, 0]
  );

  // Summaries for the table
  const summaries3 = [
    { title: 'Total Project', value: filteredData.length },
    { title: 'Total OAD', value: costFormat(totalOAD2) },
    { title: 'Total CMD', value: costFormat(totalCMD2) },
    { title: 'Total Delivered', value: costFormat(totalDelivery2) },
  ];

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook

  useEffect(() => {
    if (filteredData.length > 0) {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

      return () => {
        tooltipList.forEach(tooltip => tooltip.dispose());
      };
    }
  }, [filteredData]);  // Run tooltip initialization when `filteredData` changes


  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code Start
  const handleToggle = (index, deliveries) => {
    setDeliveries(groupByUserIdAndDate(deliveries))
    if (openAccordion === index) {
      setOpenAccordion(null); // Close the currently open accordion
      setActiveButton(null); // Remove active class from the button
    } else {
      setOpenAccordion(index); // Open the new accordion
      setActiveButton(index); // Add active class to the button
    }
  };

  const groupByUserIdAndDate = (data) => {
    const groupByDateAndUser = data.reduce((acc, curr) => {
      const date = new Date(curr.date).toISOString().split('T')[0];  // Get only the date part (YYYY-MM-DD)
      const { userId } = curr;

      if (!acc[date]) acc[date] = {};
      if (!acc[date][userId]) acc[date][userId] = [];

      acc[date][userId].push(curr);
      return acc;
    }, {});

    const result = Object.keys(groupByDateAndUser).map(date => {
      return Object.keys(groupByDateAndUser[date]).map(userId => {
        const entries = groupByDateAndUser[date][userId];
        const totalCost = entries.reduce((sum, curr) => sum + curr.deliveryCost, 0);
        const minCostEntry = entries.reduce((min, curr) => (curr.deliveryCost < min.deliveryCost ? curr : min));

        return {
          date,
          userId,
          fullName: minCostEntry.fullName,
          profilePicture: minCostEntry.profilePicture,
          deliveryCost: totalCost
        };
      });
    }).flat();  // Flatten the array since we have nested arrays
    return result;
  };

  return (
    <>
      <div className="TotlUbgset pb-24">
        <SummariesComponent summaries={summaries3} /></div>
      <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Project Code</th>
              <th>Client Name</th>
              <th>Vertical</th>
              <th>Manager</th>
              <th>OAD</th>
              <th>% OAD</th>
              <th>CMD</th>
              <th>% CMD</th>
              <th>Delivered</th>
              <th>% Deviation</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center">No data available.</td>
              </tr>
            ) : (
              filteredData.map((item, index) => {
                const deviation = calculateDeviation(item.totalDeliveryCost, item.totalcmd);
                const bgColor = getBackgroundColor(item.fullName);
                const textColor = getTextColor(bgColor);


                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.ClientProjectCode}</td>
                      <td>
                        <div
                          className="detaset"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="tooltip-primary"
                          data-bs-title={`${item.ClientRegisteredName}`}  // Dynamic tooltip content
                        >
                          {item.ClientRegisteredName}
                        </div>
                      </td>
                      <td>{item.vertical}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {item.profilePicture ? (
                            <img
                              src={item.profilePicture}
                              alt={item.fullName}
                              className="initials-image"
                              style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                            />
                          ) : (
                            <span
                              className="initials-circle UsIMgName"
                              style={{ backgroundColor: bgColor, color: textColor }}>
                              {getInitials(item.fullName)}
                            </span>
                          )}
                          {item.fullName}
                        </div>
                      </td>
                      <td className='text-end'>{costFormat(item.totalOad)}</td>
                      <td className={`text-end ${getClassName(calculatePercentage(item.totalOad, totalOAD))}`}>
                        {calculatePercentage(item.totalOad, totalOAD)}%
                      </td>
                      <td className='text-end'>{costFormat(item.totalcmd)}</td>
                      <td className={`text-end ${getClassName(calculatePercentage(item.totalcmd, totalCMD))}`}>
                        {calculatePercentage(item.totalcmd, totalCMD)}%
                      </td>
                      <td className="text-end"><div className='d-flex align-items-center gap-2 justify-content-end'>{costFormat(item.totalDeliveryCost)}
                        <button className={`btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2  ${activeButton === index ? '' : 'collapsed'}`} draggable="false" onClick={() => handleToggle(index, item?.deliveries)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16" > <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" /></svg>
                        </button></div>
                      </td>
                      <td className={`text-end ${getClassName(deviation)}`}>{deviation}%</td>
                    </tr>
                    {[...deliveries].map((delivery, i) => (
                      <React.Fragment key={i}>
                        <tr
                          id={`demo1${index}`}
                          className={`bgliggry accordion-collapse collapse ${openAccordion === index ? 'show' : ''}`}
                        >

                          <td colSpan="1" className="hiddenRow border-0 bgliggry"></td>
                          <td colSpan="6" className="hiddenRow border-0 bgliggry"></td>
                          <td className="hiddenRow border-0 bgliggry">
                            <div className="noOfhoursD">
                              <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                {delivery.date}
                              </div>
                            </div>
                          </td>
                          <td className="hiddenRow border-0 bgliggry text-center">
                            <div className="d-flex align-items-center">
                              {delivery.profilePicture ? (
                                <img
                                  src={delivery.profilePicture}
                                  alt={delivery.fullName}
                                  className="initials-image"
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    marginRight: '8px',
                                  }}
                                />
                              ) : (
                                <span
                                  className="initials-circle UsIMgName"
                                  style={{ backgroundColor: bgColor, color: textColor }}
                                >
                                  {getInitials(delivery.fullName)}
                                </span>
                              )}
                              {delivery.fullName}
                            </div>
                          </td>
                          <td className="hiddenRow border-0 bgliggry text-center">
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                              {costFormat(delivery.deliveryCost)}
                            </div>
                          </td>
                          <td className="hiddenRow border-0 bgliggry text-center">
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}

                  </>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PddstatsProject;
