import React, { useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import DatePickerComponent from './DatePickerComponent';
import TableComponent from './TableComponent';
import GroupCounter from 'src/containers/production-container/GroupCounter';
import StatCard from './StatCard';
import DateRangePickerWithAll from './DateRangePickerWithAll';
import moment from 'moment';
import AttendanceCalendar from 'src/containers/production-container/AttendanceCalendar';
import VehicleFuelChart from './VehicleFuelChart';
import ModuleSummaryPieChart from './ModuleSummaryPieChart';

Highcharts3D(Highcharts);

const ViewPerformance = () => {
    const [selectedMonth, setSelectedMonth] = useState('January'); // State for selected month

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        // You can also update the chart data based on the selected month here
    };

    const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 3,
                depth: 0,
                viewDistance: 40,
            },
        },
        title: {
            text: 'Hubstaff Hours',
            align: 'left',
            style: {
                float: 'left',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Days',
            },
        },
        yAxis: {
            title: {
                text: 'Hours',
            },
            min: 0,
        },
        series: [
            {
                name: 'Total Time',
                data: [
                    ['1 Jan', 9],
                    ['2 Jan', 8],
                    ['3 Jan', 7],
                    ['4 Jan', 9],
                    ['5 Jan', 8],
                    ['6 Jan', 7],
                    ['7 Jan', 6],
                ],
            },
            {
                name: 'Actual Time',
                data: [
                    ['1 Jan', 4],
                    ['2 Jan', 7],
                    ['3 Jan', 7],
                    ['4 Jan', 7],
                    ['5 Jan', 7],
                    ['6 Jan', 8],
                    ['7 Jan', 8],
                ],
            },
            {
                name: 'Idle Time',
                data: [
                    ['1 Jan', 3],
                    ['2 Jan', 3],
                    ['3 Jan', 4],
                    ['4 Jan', 4],
                    ['5 Jan', 4],
                    ['6 Jan', 4],
                    ['7 Jan', 5],
                ],
            },
        ],
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        tooltip: {
            shared: true,
            valueSuffix: ' hrs',
        },
    };

    // Prsntage
    const options2 = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 3,
                depth: 0,
                viewDistance: 40,
            },
        },
        title: {
            text: 'Productivity',
            align: 'left',
            style: {
                float: 'left',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Days',
            },
        },
        yAxis: {
            title: {
                text: 'Hours',
            },
            min: 0,
        },
        series: [
            {
                name: 'Total Time',
                data: [
                    ['1 Jan', 9],
                    ['2 Jan', 8],
                    ['3 Jan', 7],
                    ['4 Jan', 9],
                    ['5 Jan', 8],
                    ['6 Jan', 7],
                    ['7 Jan', 6],
                ],
            },
        ],
        colors: ['#487fff', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        tooltip: {
            shared: true,
            valueSuffix: ' %',
        },
    };

    // PIE chart
    const options3 = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Production Status',
            align: 'left'
        },
        credits: {
            enabled: false // Disable credits
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                allowPointSelect: true, // Allows slices to be selected
                cursor: 'pointer', // Changes cursor on hover
                showInLegend: true, // Ensure the pie slices appear in the legend
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}', // Show name and value on the slices
                }
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)' // Custom tooltip format
        },
        legend: {
            layout: 'horizontal',
            align: 'right', // Align legend to the right
            verticalAlign: 'top', // Position legend at the top
            floating: true, // Make the legend float above the chart
            x: -10, // Adjust horizontal position
            y: 10 // Adjust vertical position
        },
        series: [
            {
                name: 'Status', // Series name for the tooltip and legend
                data: [
                    {
                        name: 'Accepted', // Label for the slice
                        y: 16,
                        color: '#22C55E', // Custom color for 'Accepted'
                    },
                    {
                        name: 'Rejected', // Label for the slice
                        y: 12,
                        color: '#EF4444' // Custom color for 'Rejected'
                    },
                    {
                        name: 'Pending', // Label for the slice
                        y: 8,
                        color: '#FFAC1F' // Custom color for 'Pending'
                    }
                ]
            }
        ]
    };


    const HeadingEachTask = "Hours Invested and Cost of Each Project"
    // Hours invested and cost of each task data array
    const tableData = [
        {
            date: '9/20/2024',
            task: 'Project A',
            hours: 15,
            percentage: '33.33%',
            cost: 100,
            revenue: 100,
            profit: '90%',
        },
        {
            date: '9/20/2024',
            task: 'Project B',
            hours: 10,
            percentage: '25.00%',
            cost: 80,
            revenue: 150,
            profit: '85%',
        },
        {
            date: '9/21/2024',
            task: 'Project C',
            hours: 20,
            percentage: '50.00%',
            cost: 200,
            revenue: 300,
            profit: '75%',
        },
    ];

    const HeadingEachModal = "Hours Invested and Cost of Each Task"
    // Hours invested and cost of each task data array
    const tableDataModal = [
        {
            date: '9/20/2024',
            task: 'Task A',
            hours: 15,
            percentage: '33.33%',
            cost: 100,
            revenue: 100,
            profit: '90%',
        },
        {
            date: '9/20/2024',
            task: 'Task B',
            hours: 10,
            percentage: '25.00%',
            cost: 80,
            revenue: 150,
            profit: '85%',
        },
        {
            date: '9/21/2024',
            task: 'Task C',
            hours: 20,
            percentage: '50.00%',
            cost: 200,
            revenue: 300,
            profit: '75%',
        },
    ];



    // 
    // Sample data, you should replace these with your actual state or props
    const totalWorkingTime = 50; // Replace with your actual data
    const totalIdleTime = 20; // Replace with your actual data
    const totalAllowedIdleTime = 100; // Replace with your actual data 
    const totalAllowedWorkingTime = 80; // Replace with your actual data
    const groupCounter = {
        totalProjects: { total: 10 }, // Replace with your actual data
        totalLeaves: { total: 5 }, // Replace with your actual data
        totalProfit: { total: 5000 }, // Replace with your actual data
    };
    const totalLag = -2; // Replace with your actual data
    // Sample data
    const stats = [

        {
            title: 'Total Working',
            value: totalWorkingTime,
            allowedValue: totalAllowedWorkingTime,
            customClass: 'Hours-border'
        },
        {
            title: 'Total Idle',
            value: totalIdleTime,
            allowedValue: totalAllowedIdleTime,
            customClass: 'Idle-border'
        },
        {
            title: 'Projects',
            value: groupCounter.totalProjects?.total,
            customClass: 'Accepted-border'
        },
        {
            title: 'Total Leaves',
            value: groupCounter.totalLeaves?.total,
            customClass: 'Rejected-border'
        },
        {
            title: 'Lag',
            value: (
                <span className={`${totalLag < 0 ? 'text-danger-700' : 'text-success-700'}`}>
                    {totalLag}
                </span>
            ),
            customClass: 'Lag-border'
        },
        {
            title: 'Total Profit',
            value: `₹${groupCounter.totalProfit.total}`, // Add currency symbol
            customClass: 'Pending-border'
        },

    ];

    const [dateRangeHubstaff, setDateRangeHubstaff] = useState([moment().subtract(29, 'days'), moment()]);
    const [dateRangeProductivity, setDateRangeProductivity] = useState([moment().subtract(29, 'days'), moment()]);
    const [dateRangeStatus, setDateRangeStatus] = useState([moment().subtract(29, 'days'), moment()]);

    const [production, setProduction] = useState([])

 

    const projectData1 = [
        { name: 'Acadestudio', amount: 2000.50, percentage: 40, color: '#2CAFFE' },
        { name: 'Acadecraft', amount: 1200.75, percentage: 24, color: '#544FC5' },
        { name: 'Project_03', amount: 800.30, percentage: 16, color: '#FE6A35' },
        { name: 'Acadereality', amount: 1000.00, percentage: 20, color: '#00E272' },
    ];


    const projectData2 = [
        { name: 'Video Development', amount: 2000.50, percentage: 40, color: '#2CAFFE' },
        { name: 'SB TQC', amount: 1200.75, percentage: 24, color: '#544FC5' },
        { name: 'Video TQC', amount: 800.30, percentage: 16, color: '#FE6A35' },
        { name: 'VSB Development', amount: 1000.00, percentage: 20, color: '#00E272' },
    ];

 

    return (
        <div> <div className="row gy-4">
            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-16 mb-24 me-16 d-flex gap-5 align-items-center">
                            <div className="d-flex justify-content-center align-items-center perfUser">
                                <img
                                    src="/assets/images/avatar/avatar1.png"
                                    alt=""
                                    className="border br-white border-width-2-px rounded-circle object-fit-cover"
                                />
                            </div>
                            <div className="mt-24 w-100">
                                <h6 className="text-xl mb-16">Details of employee</h6>
                                <ul className="gap-0" style={{ display: "grid", gridTemplateColumns: "repeat(2 ,1fr)" }}>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            Name
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : Will Jonto
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Employee Id
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : 5656
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Designation
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : UI UX Designer
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Duration
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">: Design</span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Email
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : willjontoax@gmail.com
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Mobile No.
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">: (1) 2536 2561 2365</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="row row-cols-xxxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-4">
                            {stats.map((stat, index) => (
                                <StatCard
                                    key={index}
                                    title={stat.title}
                                    value={stat.value}
                                    allowedValue={stat.allowedValue}
                                    customClass={stat.customClass}
                                />
                            ))}
                        </div>
                        <AttendanceCalendar />
                        <div className="d-flex flex-wrap align-items-center gap-4 py-16 border-bottom">
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-green-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Presents </span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-pink-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Holidays </span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Sick Leave </span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-blue-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Casual Leave</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-end inwth">
                            {/* <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> */}
                            <DateRangePickerWithAll value={dateRangeHubstaff} onChange={setDateRangeHubstaff} />
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-end inwth">
                            {/* <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> */}
                            <DateRangePickerWithAll value={dateRangeStatus} onChange={setDateRangeStatus} />
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options3}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-end inwth">
                            {/* <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> */}
                            <DateRangePickerWithAll value={dateRangeProductivity} onChange={setDateRangeProductivity} />
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options2}
                        />
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24 px-0">
                        <div className="ms-auto d-flex justify-content-between border-bottom pb-24 align-items-center inwth">
                            <h4 className="card-title">Project Summary</h4>   <DateRangePickerWithAll value={dateRangeProductivity} onChange={setDateRangeProductivity} />
                        </div>
                        <div className="card-body px-0 pt-18">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul
                                    className="nav focus-tab nav-pills mb-0"
                                    id="pills-tab-two"
                                    role="tablist"
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-6 active"
                                            id="pills-focus-GrossWorkingDays-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-focus-GrossWorkingDays"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-focus-GrossWorkingDays"
                                            aria-selected="true"
                                        >
                                            Gross Working Days
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-6"
                                            id="pills-focus-TotalWorkingDays-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-focus-TotalWorkingDays"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-focus-TotalWorkingDays"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Total Working Days
                                        </button>
                                    </li>
                                </ul>
                                <div className="d-flex align-items-center flex-wrap gap-28">
                                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal1"
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal1"
                                        >
                                            {" "}
                                            Approved {" "}
                                        </label>
                                    </div>
                                    <div className="form-check checked-warning d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal4"
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal4"
                                        >
                                            {" "}
                                            Pending{" "}
                                        </label>
                                    </div>
                                    <div className="form-check checked-success d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="horizontal"
                                            id="horizontal3"
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                            htmlFor="horizontal3"
                                        >
                                            {" "}
                                            Total{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-content" id="pills-tab-twoContent">
                                <div
                                    className="tab-pane fade active show"
                                    id="pills-focus-GrossWorkingDays"
                                    role="tabpanel"
                                    aria-labelledby="pills-focus-GrossWorkingDays-tab"
                                    tabIndex={0}
                                >
                                   
                                            <VehicleFuelChart projects={projectData1} totalProjectsLabel="Total Projects" showModalButton={false} /> {/* Pass chart data */}
                                       
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="pills-focus-TotalWorkingDays"
                                    role="tabpanel"
                                    aria-labelledby="pills-focus-TotalWorkingDays-tab"
                                    tabIndex={0}
                                >
                                     <VehicleFuelChart projects={projectData1} totalProjectsLabel="Total Projects" showModalButton={false} /> {/* Pass chart data */}
                                </div>
                            </div>
                        </div>


                        <div className="text-center"><button className="btn text-primary-600 border-0 radius-8 pt-20 py-24 pb-8">View Details</button></div>
                    </div>
                </div>
            </div>

          
            <div className="col-md-12">
      <div className="card h-100 p-0">
        <div className="card-body p-24">
          <div className="ms-auto d-flex justify-content-between pb-12 align-items-center inwth">
            <h4 className="card-title">Module Summary <span className="text-neutral-500">(Acadestudio)</span></h4>
          </div>
          <div className="col-md-12">
            <ModuleSummaryPieChart projectTasks ={[]}/>
            <div className="text-center">
              <button 
                className="btn text-primary-600 border-0 radius-8 pt-20 py-24 pb-8" 
                 
              >
                Hide
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
        
            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-between pb-12 align-items-center inwth">
                            <h4 className="card-title">Task Summary  <span className="text-neutral-500">(M 4588)</span></h4>
                        </div>
            <VehicleFuelChart projects={projectData2} totalProjectsLabel="Total Task" showModalButton={false} /> {/* Pass chart data */}
            <div className="text-center"><button className="btn text-primary-600 border-0 radius-8 pt-20 py-24 pb-8">Hide</button></div>
            </div>
            </div>
            </div>

            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <TableComponent heading={HeadingEachTask} tableData={tableData} />
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <TableComponent heading={HeadingEachModal} tableData={tableDataModal} />
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-menu-app-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                                </svg>{" "}
                                Production
                            </h6>
                        </div>
                        <div
                            className="table-responsive table-container"
                            id="table-container"
                        >
                            <table className="table basic-border-table mb-0 table-hover">
                                <thead>
                                    <tr className="dnd-moved">
                                        <th className="w30px dragablefalse" draggable="false">
                                            <input className="form-check-input" type="checkbox" />{" "}
                                        </th>
                                        <th className="w30px dragablefalse" draggable="false">
                                            <label className="form-check-label">S.No.</label>
                                        </th>
                                        <th>Date </th>
                                        <th>Project Code </th>
                                        <th>Task Name </th>
                                        <th className="text-center">Number </th>
                                        <th className="text-center">Item </th>
                                        <th className="text-center">Unit </th>
                                        <th className="text-center">Cost </th>
                                    </tr>
                                </thead>
                                {production?.map((production, index) => (
                                    <tbody id="table-body">
                                        <tr className="dnd-moved">
                                            <td draggable="false">
                                                <input className="form-check-input" type="checkbox" />
                                            </td>
                                            <td draggable="false">
                                                <label className="form-check-label">{index + 1}</label>
                                            </td>
                                            <td>{moment(production.date).format('DD-MM-YYYY')}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-2 fw-medium text-primary-600">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        className="bi bi-stack"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
                                                        <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
                                                    </svg>{" "}
                                                    {production?.clientId?.ClientProjectCode}
                                                </div>
                                            </td>
                                            <td>{production?.taskId?.taskName}</td>
                                            <td className="text-center">
                                                <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        className="bi bi-check-circle-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fill="#02BC7D"
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                                        ></path>
                                                    </svg>{" "}
                                                    {production?.totalDurations}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    style={{
                                                        backgroundColor:
                                                            production?.taskId?.item === 'Daily Target'
                                                                ? 'lightgreen'
                                                                : production?.taskId?.item === 'Bound'
                                                                    ? 'purple'
                                                                    : production?.taskId?.item === 'Open'
                                                                        ? 'orange'
                                                                        : 'transparent',
                                                    }}
                                                    className="btn"
                                                >
                                                    {production?.taskId?.item}
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    style={{
                                                        backgroundColor:
                                                            production?.taskId?.unit === 'Per Day'
                                                                ? 'lightgreen'
                                                                : production?.taskId?.unit === 'min'
                                                                    ? 'purple' : 'transparent'


                                                    }}
                                                    className="btn"
                                                >
                                                    {production?.taskId?.unit}
                                                </button>{" "}
                                            </td>
                                            <td className="text-center">
                                                <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                                                    ₹ {production?.cost || 0}
                                                </span>
                                            </td>
                                        </tr>

                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    );
}

export default ViewPerformance;
