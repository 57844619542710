import { Link } from 'react-router-dom';

const EmployeeListRow = (props) => {

  // console.log("props props2", props.reportFilter);

  // Function to get the initials from the name
  const { user, index, handleEditProfile, updateStatus } = props
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
    return initials.toUpperCase();
  };
  // Function to generate a dynamic background color based on the user's name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 60%, 70%)`; // Adjust saturation and lightness as needed
    return color;
  };


  const initials = getInitials(`${user.firstName} ${user.lastName}`);
  const backgroundColor = getBackgroundColor(`${user.firstName} ${user.lastName}`);
  return (
    <tr key={user._id} className="dnd-moved">
      <td draggable="false">
        <label className="form-check-label">{index + 1}</label>
      </td>
      <td>
        <span className="bg-primary-focus btn-primary-900 text-bas px-24 py-2 rounded-pill fw-medium text-sm">
          {user.employeeID}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center gap-2">
          <span className="userimg d-flex align-items-center justify-content-center">
            {user.profilePicture ? (
              <img src={user.profilePicture} alt="Profile" />
            ) : (
              <span
                className="UsIMgName me-0"
                style={{ backgroundColor }}
              >
                {initials}
              </span>
            )}
          </span>
          <div>
            <h6 className="text-sm mb-0 fw-medium text-primary-600">
              {user.firstName} {user.lastName}
            </h6>

          </div>
        </div>
      </td>
      <td>{user.departmentId?.departmentName || 'N/A'}</td>
      <td>{user.designationId?.designationName || 'N/A'}</td>
      <td className="text-center">
        <div className="dropdown">
          <button
            className="btn px-18 py-11 text-primary-light"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <iconify-icon icon="ph:dots-three-outline-fill" className="menu-icon" />
          </button>
          <ul className="dropdown-menu border px-0 py-4" style={{}}>
            <li>
              {/* <Link
                                      to={`/view-perfomance/${user.employeeID}/${user._id}`}
                                      className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm"
                                      >  View Perfomance
                                      </Link> */}
              <a
                href={`/view-perfomance/${user.employeeID}/${user._id}/${props.reportFilter.month}/${props.reportFilter.year}`}
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm"
              >
                View Performance
              </a>
            </li>
            <li>
              <a
                href={`/view-production/${user._id}`}
                target="_blank"
                className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm"
              >  View Production
              </a>
            </li>
            <li>
              <a
                href={`/add-salary-slip/${user._id}`}
                target="_blank"
                className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm"
              >  View Salary
              </a>
            </li>
            <li>
              <Link
                className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm"
                href="javascript:void(0)"
                data-bs-toggle="offcanvas" data-bs-target="#UserInfo" aria-controls="UserInfo"
                onClick={() => { handleEditProfile(user) }}
              > Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item px-16 py-4 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 text-sm text-danger-700"
                href="javascript:void(0)"
                onClick={() => { updateStatus(user._id) }}
              > De-Activate
              </Link>
            </li>
          </ul>
        </div>

      </td>
    </tr>
  )
}
export default EmployeeListRow