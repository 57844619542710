import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";

const Select2Component = ({ options, placeholder, onChange, value }) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const $select = $(selectRef.current);

    // Initialize Select2
    $select.select2({
      placeholder: placeholder,
      allowClear: true,
    });

    // Set default selected value (if any)
    if (value !== undefined && value !== null) {
      $select.val(value).trigger("change");
    }

    // Attach event listener
    $select.on("change", (e) => {
      const selectedValue = e.target.value === "" ? null : e.target.value;
      onChange(selectedValue);
    });

    // Cleanup function
    return () => {
      $select.off("change");
      $select.select2("destroy");
    };
  }, [placeholder, onChange, options]); // Removed `value` to prevent infinite loop

  // Effect to update value dynamically without re-initializing Select2
  useEffect(() => {
    const $select = $(selectRef.current);
    if (value !== undefined && value !== null) {
      $select.val(value).trigger("change");
    } else {
      $select.val("").trigger("change");
    }
  }, [value]); // Separate effect only for value updates

  return (
    <select ref={selectRef} style={{ width: "100%" }}>
      <option value="">Select an option</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select2Component;
