import React from 'react'
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';

Highcharts3D(Highcharts);

const LineGraph = ({ series }) => {
    const lineChartOptions = {
        chart: {
            type: 'line',
        },
        title: {
            text: '',
            align: 'left',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: '',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            min: 0,
        },
        series: series,
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: -10,
        },
        tooltip: {
            shared: true,
            valueSuffix: '',
        },
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={lineChartOptions}
        />
    )
}

export default LineGraph