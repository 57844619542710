import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { userProductionFileAPI } from 'src/api/ProductionApi';

const FileUpload = ({ files, setFiles, accept = "image/*,application/pdf" }) => {
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState(files);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleButtonClick = () => {
    // Trigger click on hidden file input
    fileInputRef.current.click();
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files).map((file) => ({
  //     file,
  //     preview: URL.createObjectURL(file),
  //   }));
  //   console.log("uploadedFiles", uploadedFiles);

  //   setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  //   fileInputRef.current.value = null; // Reset input value to allow re-selecting the same files
  // };

  // const handleRemoveFile = (index) => {
  //   const updatedFiles = [...uploadedFiles];
  //   // Revoke the preview URL to free memory
  //   URL.revokeObjectURL(updatedFiles[index].preview);
  //   updatedFiles.splice(index, 1);
  //   setUploadedFiles(updatedFiles);
  // }

  const handleFileChange22 = async (event) => {

    const files2 = Array.from(event.target.files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setUploadedFiles((prevFiles) => [...prevFiles, ...files2]);
    const files = event.target.files;
    const payload = { file: files[0], by: userId };
    const fileResponse = await userProductionFileAPI(payload);
    setFiles(prevFiles => [...prevFiles, fileResponse.data.data]);
    fileInputRef.current.value = null; // Reset input value to allow re-selecting the same files
  }

  const handleFileChange = async (event) => {
    // Extract selected files
    const selectedFiles = Array.from(event.target.files);

    // Generate preview URLs and prepare for upload
    const filesWithPreviews = selectedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    // Update state to include the newly selected files with previews
    setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreviews]);

    // Loop through each file to handle the API upload
    const uploadedFiles = [];
    for (const file of selectedFiles) {
      try {
        const payload = { file, by: userId };
        const fileResponse = await userProductionFileAPI(payload);
        uploadedFiles.push(fileResponse.data.data);
      } catch (error) {
        console.error("Error uploading file:", file.name, error);
        // Optionally: Handle individual file upload errors here
      }
    }

    // Update the state with successfully uploaded files
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

    // Reset the file input value to allow re-selection of the same files
    fileInputRef.current.value = null;
  }


  const handleRemoveFile = async (index) => {
    const updatedFiles = [...uploadedFiles];
    // Revoke the preview URL to free memory
    URL.revokeObjectURL(updatedFiles[index]?.preview || updatedFiles[index]);
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);

    // Remove only from the preview array, not the final API array
    const updatedFilesApi = files.filter((_, i) => i !== index);
    setFiles(updatedFilesApi);

    // const remove = await productionFIleRemoveAPI({ ids: [data._id] })
    // if (remove)
    //   setFiles(files.filter(item => item._id !== data._id))
  }

  useEffect(() => {
    console.log("uploadedFiles", uploadedFiles);

  }, [handleFileChange, handleRemoveFile])

  const handleImageClick = (image) => {
    setSelectedImage(image);
    console.log("imageimage", image);

  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="my-16">
      <label className="form-label">Upload Client Approval Attachments</label>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        {files.map((fileObj, index) => {
          console.log(fileObj, "fileObj");
          const filePath = fileObj?.url || fileObj

          return (
            <>
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '154px',
                  height: '95px',
                  background: '#ECECEC',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageClick(filePath)}
              >
                {filePath?.endsWith(".pdf") ? (
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <iframe
                      title="Image Preview"
                      src={filePath}
                      style={{
                        width: '112%',
                        overflow: "hidden",
                        height: '100%',
                        border: 'none',
                      }}
                    />
                    <div
                      onClick={() => handleImageClick(filePath)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }}
                    ></div>

                    <button className='buttonCloss'
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the image click event
                        handleRemoveFile(index);
                      }}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      &times;
                    </button>
                  </div>
                ) : (
                  <>
                    <img
                      src={filePath}
                      alt={fileObj?.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />

                    <button className='buttonCloss'
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the image click event
                        handleRemoveFile(index);
                      }}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      &times;
                    </button>
                  </>
                )}
                {/* <img
              src={fileObj?.url || fileObj}
              alt={fileObj?.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            /> */}
                {/* <iframe onClick={() => handleImageClick(fileObj?.url || fileObj)}
              title="Image Preview"
              src={fileObj?.url || fileObj}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            /> */}



              </div>
            </>
          )
        })}
        <div className='Uploadbnsb'
          style={{
            width: '154px',
            height: '95px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#ECECEC',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onClick={handleButtonClick}
        >
          <div
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#007bff',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '24px',
            }}
          >
            +
          </div>
        </div>
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          multiple // Enable multiple file selection
          accept={accept} // Restrict to image files only
        />
      </div>

      {/* Modal for showing selected image */}
      {selectedImage && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              position: 'relative',
              maxWidth: '100%',
              maxHeight: '100%',
              width: '90%',
              height: '90%',
              backgroundColor: '#fff',
              padding: '10px',
              borderRadius: '8px',
              overflow: 'auto'
            }}
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >

            {selectedImage?.endsWith(".pdf") ? (
              <iframe className='imgsetupnew'
                title="Preview"
                src={selectedImage}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <img className='img-fluid'
                src={selectedImage}
                alt="Selected"
                style={{

                }}
              />
            )}

            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
