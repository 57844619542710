import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import ReportTab from '../ReportTab';
import SalesForcast from './SalesForcast';
import { salesReports } from 'src/api/ReportsAPI';
import SaleConversion from './SaleConversion';
import RevenueBDM from './RevenueBDM';

Highcharts3D(Highcharts);

const SalesReportContainer = () => {

    // salesReports

    const [data, setData] = useState([])
    const [bdm, setBdm] = useState([])
    const [where, setWhere] = useState({
        year: 2024,
        month: 12,
    });
    const apiData = async () => {
        try {
            const resp = await salesReports({});
            // console.log("resp", resp?.data?.data);
            setData(resp?.data?.data)
            setBdm(resp?.data?.bdm)
        } catch (err) {
            console.error('Failed to operation report. ' + err.message);
        }
    }
    useEffect(() => {
        apiData()
    }, [])

    return (
        <div className="dashboard-main-body">
            <div className='row'>
                <div class="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-body p-24">
                            <ReportTab />
                            {/* ============== */}
                            <SalesForcast data={data} />
                            {/* ================== */}
                        </div>
                    </div>
                    {/* Start Sales Conversion */}
                    <div className="card mt-12">
                        {/* ============== */}
                        <SaleConversion data={data} bdm={bdm} />
                        {/* ================== */}
                    </div>
                    {/* End Sales Conversion */}

                    {/* Start Salary Department Chat sec    */}
                    <div className="card mt-12">
                        {/* ============== */}
                        <RevenueBDM />
                        {/* ================== */}
                    </div>
                    {/* End Salary Department Chat sec    */}
                </div>
            </div>
        </div >

    )
}

export default SalesReportContainer