import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { userSearchAPI } from "src/api/UserApi";
import Loading from 'src/containers/main-container/Loading';

const Information = ({ user, setUser, salaryDetails, setSalaryDetails }) => {
    const { employeeID, userId } = useParams();
    const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
    const [isLoading, setIsLoading] = useState(false);
    const [where, setWhere] = useState({
        _id: userId,
        month: selectedMonth,
        year: selectedYear
    });

    useEffect(() => {
        search(userId)
    }, [where])
    const search = useCallback(async () => {
        setIsLoading(false)
        const user = await userSearchAPI({ where, autopopulate: true })
        setUser(user.data.data[0])
        setSalaryDetails(user.data.salaryResult.length > 0 ? user.data.salaryResult[0].netSalary : user.data.data[0].monthlySalary)
        setIsLoading(true)
    }, [where])

    useEffect(() => {
        setWhere({
            ...where,
            month: selectedMonth,
            year: selectedYear
        });
    }, [selectedMonth, selectedYear]);
    return (
        <>
            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-16 mb-24 me-16 d-flex gap-5 align-items-center">
                            <div className="d-flex justify-content-center align-items-center perfUser">
                                <img
                                    src=
                                    {user?.profilePicture ? user?.profilePicture : '/assets/images/avatar/avatar1.png'}
                                    alt=""
                                    className="border br-white border-width-2-px rounded-circle object-fit-cover"
                                    onError={(e) => { e.target.src = "/assets/images/avatar/avatar1.png"; }}
                                />
                            </div>
                            <div className="mt-24 w-100">
                                <h6 className="text-xl mb-16">Details of employee</h6>
                                <ul className="gap-0" style={{ display: "grid", gridTemplateColumns: "repeat(2 ,1fr)" }}>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            Name
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : {(user?.firstName || "") + " " + (user?.lastName || "")}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Employee Id
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : {user?.employeeID}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Designation
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : {user?.designationId?.designationName}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Department
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">: {user?.departmentId?.departmentName}</span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Email
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">
                                            : {user?.email}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-center gap-1 mb-12">
                                        <span className="w-30 text-md fw-semibold text-primary-light">
                                            {" "}
                                            Salary.
                                        </span>
                                        <span className="w-70 text-secondary-light fw-medium">: {salaryDetails}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Information