import React, { useState, useEffect } from 'react';
import TopTabs from "../TopTabs"
import ModuleFilter from "./FIlter"
import ManageModuleColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { moduleProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { shallowEqual, useSelector } from 'react-redux';
import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';
import { convertMinutesToHours, costFormat } from 'src/utils/CommonUtils';


const ModuleWiseContainer = () => {

  const { projectId } = useParams();
  const [module, setModule] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState('');
  const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''

  const getProjectModuleData = async () => {
    let body = {
      pageLength: 100
    };

    try {
      const resp = await moduleProjectSearchAPI({ projectId, body });
      console.log('response from project Task', resp?.data?.data);

      const skillsData = resp?.data?.data || [];

      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item //?.moduleName?.toLowerCase().includes(search.toLowerCase())
      );

      // Calculate total duration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.approvedDurations || 0;
        acc.totalCost += item.cost || 0;
        return acc;
      }, { totalDuration: 0, totalCost: 0 });
      // Set the filtered skills and totals in state
      setModule(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);

    } catch (error) {
      console.log('error from project Task', error);
    }
  };

  useEffect(() => {
    getProjectModuleData();
  }, [search]);

  const handleDateRangeChange = (selectedDates) => {

  }
  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">

              <BackProjectButton />

              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Module</li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                {/* <SearchInput /> */}
                {/* Search End */}
                {/* Date Range piker start  */}
                {/* <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> */}
                {/* Date Range piker end  */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {projectCode}
                </h6>
              </div>
              <div className="table-responsive1" id="table-container">
                <table className="table basic-border-table mb-0 table-hover Tbltopfixedsciky">
                  <thead>
                    <tr className="dnd-moved">
                      {/* <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th> */}
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Module Name </th>
                      <th className="text-center">Hrs </th>
                      <th className="text-center">Minutes/Units </th>
                      <ProjectPermissionHOC scope="view_report.module.cost">
                        <th className="text-center">Cost</th>
                      </ProjectPermissionHOC>
                    </tr>
                  </thead>
                  <tbody>
                    {module?.map((item, index) => {
                      console.log("index", index); // Logs index during iteration

                      // Grouping data by taskId and then by userId
                      const groupedData = item?.taskDetails.reduce((acc, item) => {
                        const { taskId, taskName, userId, fullName, cost, approvedDurations } = item;

                        // Ensure the task group exists
                        if (!acc[taskId]) {
                          acc[taskId] = {
                            taskId,
                            taskName,
                            totalCost: 0,
                            totalApprovedDurations: 0,
                            users: {},
                          };
                        }

                        // Update task-level totals
                        acc[taskId].totalCost += cost;
                        acc[taskId].totalApprovedDurations += approvedDurations;

                        // Ensure the user group exists under the task
                        if (!acc[taskId].users[userId]) {
                          acc[taskId].users[userId] = {
                            userId,
                            fullName,
                            totalCost: 0,
                            totalApprovedDurations: 0,
                          };
                        }

                        // Update user-level totals
                        acc[taskId].users[userId].totalCost += cost;
                        acc[taskId].users[userId].totalApprovedDurations += approvedDurations;

                        return acc;
                      }, {});

                      // Convert to the desired structure
                      const taskList = Object.values(groupedData).map(task => ({
                        ...task,
                        users: Object.values(task.users),
                      }));

                      return (
                        <>
                          <tr className="dnd-moved">
                            {/* <td>
                          <input className="form-check-input" type="checkbox" />
                        </td> */}
                            <td draggable="false">
                              <label className="form-check-label">{index + 1}</label>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <button
                                  className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                                  draggable="false"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#demo1${index}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-caret-down-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                  </svg>
                                </button>
                                <div className="d-flex align-items-center gap-2 fw-medium text-primary-600">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-stack"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
                                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
                                  </svg>{" "}
                                  {item?.moduleName}
                                </div>
                              </div>
                            </td>
                            <td className="text-center">{convertMinutesToHours(item?.approvedDurations)}</td>
                            <td>
                              <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-check-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="#02BC7D"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                  />
                                </svg>{" "}
                                {item?.approvedDurations}
                              </div>
                            </td>
                            <ProjectPermissionHOC scope="view_report.module.cost">
                              <td className="text-center">
                                <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                                  {costFormat(item?.cost)}
                                </span>
                              </td>
                            </ProjectPermissionHOC>
                          </tr>
                          <tr className="bgliggry">
                            <td colSpan={1} className="hiddenRow border-0 p-0 bgliggry" />
                            <td colSpan={4} className="hiddenRow border-0 p-0 bgliggry">
                              <div
                                id={`demo1${index}`}
                                className="accordion-collapse collapse uldropdn"
                              >
                                {/* {item?.taskDetails?.map((item, index) => ( */}
                                {taskList
                                  .sort((a, b) => a.taskName.localeCompare(b.taskName)) // Sorting tasks by name
                                  ?.map((item, index) => (
                                    <>
                                      <ul key={index}>
                                        <li />
                                        <li className='d-flex align-items-center gap-2'><button
                                          className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                                          draggable="false"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#detailsRow${index}`} // Reference to the collapsible row
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                          </svg>
                                        </button> {item?.taskName}</li>
                                        <li className="text-center">
                                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              className="bi bi-check-circle-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill="#D0D4E4"
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                              ></path>
                                            </svg>{" "}
                                            {convertMinutesToHours(item?.totalApprovedDurations)}
                                          </div>
                                        </li>
                                        <li className="text-center">
                                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              className="bi bi-check-circle-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill="#D0D4E4"
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                              ></path>
                                            </svg>{" "}
                                            {item?.totalApprovedDurations}
                                          </div>
                                        </li>
                                        <ProjectPermissionHOC scope="view_report.module.cost">
                                          <li className="text-center">{costFormat(item?.totalCost)}</li>
                                        </ProjectPermissionHOC>

                                      </ul>
                                      {/* Second loop: Users */}
                                      {Array.isArray(item.users) &&
                                        item.users
                                          .sort((a, b) => a.fullName.localeCompare(b.fullName)) // Sorting users by name
                                          ?.map((user, userIndex) => (

                                            <div key={userIndex} id={`detailsRow${index}`} className="collapse bgliggry">
                                              <div className="w-100 hiddenRow border-0 p-0 bgliggry">
                                                <div className="uldropdn">
                                                  <ul>
                                                    <li></li>
                                                    <li className="text-start">{user?.fullName}</li>
                                                    <li className="text-center">
                                                      <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width={16}
                                                          height={16}
                                                          fill="currentColor"
                                                          className="bi bi-check-circle-fill"
                                                          viewBox="0 0 16 16"
                                                        >
                                                          <path
                                                            fill="#D0D4E4"
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                                          ></path>
                                                        </svg>{" "}
                                                        {user?.totalApprovedDurations}
                                                      </div>
                                                    </li>
                                                    <ProjectPermissionHOC scope="view_report.module.cost">
                                                      <li className="text-center">{costFormat(user?.totalCost)}</li>
                                                    </ProjectPermissionHOC>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                    </>
                                  ))}
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    }
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={1} />
                      <td className="fw-bold">
                        Total
                      </td>
                      <td className="fw-bold">
                        {convertMinutesToHours(totalDuration)}
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration}</span> Total Units
                      </td>
                      <ProjectPermissionHOC scope="view_report.module.cost">
                        <td className="text-center">
                          <span className="d-block fw-bold">{costFormat(totalcost)}</span> Total Cost
                        </td>
                      </ProjectPermissionHOC>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
      <ModuleFilter />
      <ManageModuleColum />
    </>
  )
}
export default ModuleWiseContainer